<h1 mat-dialog-title>
  {{'general.error.session.title' | translate}}
</h1>
<div mat-dialog-content>
  <p>
    {{'general.error.session.text1' | translate}}
  </p>
</div>
<div mat-dialog-actions class="actions-container">
  <button type="button" class="save" (click)="onSave()">
    {{'general.action-buttons.ok' | translate}}
  </button>
</div>
