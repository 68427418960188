import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable, of, throwError} from "rxjs";
import {catchError, retryWhen, switchMap} from 'rxjs/operators';
import { MatDialog } from "@angular/material/dialog";
import { ErrorDialogComponent } from "./error-dialog/error-dialog.component";
import { INTERCEPTOR_EXCEPTIONS } from "src/app/shared/constants/interceptor-exceptions";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    public dialog: MatDialog
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const callType = req.url.split('/').pop();
    if (!INTERCEPTOR_EXCEPTIONS.includes(callType)) {
      return next.handle(req).pipe(
        retryWhen((error$) => {
          return error$.pipe(switchMap((error) => {
            const dialogRef = this.dialog.open(ErrorDialogComponent,
              {
                width: '712px',
                height: '408px',
                data: error.error,
                panelClass: 'error-dialog'
              }
            )

            return dialogRef.afterClosed().pipe(
              switchMap((result) => {
                if (result) {
                  return of(null);
                } else {
                  return throwError(error);
                }
              })
            );
          }))
        }),
        catchError((error) => {
          return throwError(error.message);
        })
      )
    } else {
      return next.handle(req)
    }
  }
}
