import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-register-device-dialog",
  templateUrl: "./register-device-dialog.component.html",
  styleUrls: ["./register-device-dialog.component.scss"],
})
export class RegisterDeviceDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RegisterDeviceDialogComponent>,
  ) {}

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close(undefined);
  }
}
