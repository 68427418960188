<div mat-dialog-content>
  <ng-lottie
    height="320px"
    [options]="lottieOptions"
    (loopComplete)="animationControl()">
  </ng-lottie>
  <p class="loading-status-text">
    {{loadingStatusText | translate}}
  </p>
</div>
<div mat-dialog-actions>
  <button type="button"
    class="cancel"
    (click)="onClick(undefined)"
    *ngIf="!lastAnimationCompleted">
    {{'general.action-buttons.cancel' | translate}}
  </button>
  <button type="button"
    class="ok"
    (click)="onClick(true)"
    [disabled]="!lastAnimationCompleted">
    {{'general.action-buttons.ok' | translate}}
  </button>
</div>
