<h1 mat-dialog-title>
  {{'general.popup.delete.title' | translate}}
</h1>
<div mat-dialog-content>
  <p>{{'general.popup.delete.text1' | translate}} {{data.deletableElementType}} ?</p>
</div>
<div mat-dialog-actions align="end">
  <button type="button" class="cancel" (click)="onNoClick()">
    {{'general.action-buttons.cancel' | translate}}
  </button>
  <button type="button" class="delete" (click)="onSubmit()" >
    {{'general.action-buttons.ok' | translate}}
  </button>
</div>
