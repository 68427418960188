import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
	providedIn: 'root'
})
export class FaqService {

	constructor(
		private firestore: AngularFirestore
	) { }

  getFaq() {
		return this.firestore.doc('static/faq').snapshotChanges();
  }
}
