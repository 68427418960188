<section class="dialog-container">
  <h1 mat-dialog-title>
    {{'devices.hint-popup.title' | translate}}
  </h1>
  <div mat-dialog-content>
    <p>
      {{'devices.hint-popup.text1' | translate}}
    </p>
    <div class="register-container">
      <img src="../../../../../assets/styles/images/img_device-register.svg" alt="Register device">
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button type="button" class="delete" (click)="onNoClick()">
      {{'general.action-buttons.ok' | translate}}
    </button>
  </div>
</section>
