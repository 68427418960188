<mat-toolbar *ngIf="authService.user">
  <button (click)="snav.toggle()" class="touchbud-logo">
    <img src="../assets/styles/images/touchbud_play_logo.svg" >
  </button>
  <ng-container *ngIf="authService.activeCompanies | async as companies">
    <mat-form-field class="company-formfield"
      *ngIf="companies.length > 1">
      <mat-label *ngIf="!activeSelect">{{authService.company}}</mat-label>
      <mat-select class="company-select">
        <mat-option *ngFor="let company of companies"
          (click)="setActiveCompany(company.id)"
          [value]="company.id"
          >{{company.id}}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
  <section class="right">
    <button class="faq" (click)="openFaq()">
      <img src="../assets/styles/images/icons/ic_faq.svg">
    </button>
    <section class="language-container">
      <button class="language"
              [ngClass]="{'active-language' : translate.currentLang === 'en'}"
              (click)="useLanguage('en', authService.user.id)">EN</button>
      <div class="border"></div>
      <button class="language"
              [ngClass]="{'active-language' : translate.currentLang === 'de'}"
              (click)="useLanguage('de', authService.user.id)">D</button>
    </section>
    <section class="profile-container">
      <div class="profile-nick"
        [ngClass]="authService.user.role === 'admin'
          ? 'admin'
          : (authService.user.role === 'editor')
            ? 'editor' : 'subscriber'">
        <span>{{authService.user.role | role}}</span>
      </div>
      <div class="profile-full">
        <p class="fullname">{{authService.user.name}}</p>
        <p class="role">{{authService.user.role}}</p>
      </div>
    </section>
  </section>
</mat-toolbar>
<mat-sidenav-container class="content-container"
                      [ngClass]="{'is-signed-in': authService.user}"
                      fullscreen>
  <mat-sidenav #snav
    mode="side"
    opened
    *ngIf="authService.user">
    <app-navigation></app-navigation>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
