import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
	providedIn: 'root'
})
export class UsersService {

	constructor(
		private firestore: AngularFirestore
	) { }

	getUsers() {
		return this.firestore.collection('users').snapshotChanges();
	}

	getUserById(userId: string) {
		return this.firestore.doc('users/' + userId).snapshotChanges();
	}

  getUserCompanies(userId: string) {
    return this.firestore.collection('users/' + userId + '/companies').snapshotChanges();
  }

	changeRole(userId: string, userRole: string, company: string) {
		return this.firestore.collection('users').doc(userId).collection('companies').doc(company).update({
      role: userRole
    })
	}

  changeLanguage(userId: string, selectedLanguage: string) {
    return this.firestore.doc('users/' + userId).update(
      {
        language: selectedLanguage
      }
    );
  }

	deleteUser(id: string) {
		return this.firestore.collection('users').doc(id).delete();
	}
}
