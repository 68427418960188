<h1 mat-dialog-title>
  {{'general.popup.warning.title' | translate}}
</h1>
<div mat-dialog-content>
  <p>
    {{'general.popup.warning.text1' | translate}}
    {{data.assigned}}
    {{'general.popup.warning.text2' | translate}}
    {{data.assignedTo}}
    {{'general.popup.warning.text3' | translate}}
    {{'general.popup.warning.text4' | translate}}
    {{data.assigned}}
    {{'general.popup.warning.text5' | translate}}
    {{data.assignedTo}}
    {{'general.popup.warning.text6' | translate}}
  </p>
  <div class="playlists-name">
    <span *ngFor="let value of data.values">
      {{value}}
    </span>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button type="button" class="cancel" (click)="onNoClick()">
    {{'general.action-buttons.ok' | translate}}
  </button>
</div>
