import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';

import { Subscription } from 'rxjs';

import { ServerService } from 'src/app/shared/services/server.service';
import { ExpiredDialogComponent } from './components/expired-dialog/expired-dialog.component';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
  user: FormGroup;
  loading = false;
  lottieOptions: AnimationOptions = {
    path: '/assets/animation/anim_loading.json',
  };

  // Test URL
  // resetpassword?uid=uid&email=kisorsiadam@gmail.com&token=asd

  private userEmail = '';
  private userUid = '';
  private userToken = '';
  private signUpSubs!: Subscription

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly serverService: ServerService,
    private readonly dialog: MatDialog
  ) { }

  ngOnInit() {
    this.userUid = this.router.url.split('?')[1].split('&')[0].split('=')[1];
    this.userEmail = this.router.url.split('?')[1].split('&')[1].split('=')[1];
    this.userToken = this.router.url.split('?')[1].split('&')[2].split('=')[1];
    this.user = this.fb.group({
      email: new FormControl(this.userEmail),
      password: this.fb.group({
        newPassword: new FormControl('',
          [
            Validators.required,
            Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+.@#$%^&*-])(.{8,})')
          ]),
        rePassword: new FormControl('',
          [
            Validators.required
          ])
      }, { validator: this.checkPasswords }),
      terms: new FormControl(false)
    })
  }

  onSubmit(formvalue) {
    this.loading = true;
    const body = {
      password: formvalue.password.newPassword,
      confirmpassword: formvalue.password.rePassword,
      token: this.userToken
    }

    this.signUpSubs = this.serverService.changePassword(this.userUid, body).subscribe((e) => {
      this.loading = false;
      this.router.navigate(['login']);
    }, (error) => {
      const dialogRef = this.dialog.open(ExpiredDialogComponent, {
        width: '560px'
      })

      dialogRef.afterClosed().subscribe(() => {
        this.loading = false;
        this.router.navigate(['login']);
      })
    })
  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls.newPassword.value;
    const confirmPass = group.controls.rePassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  checkPasswordCondition(conditionType: string): boolean {
    switch (conditionType) {
      case 'length':
        return (this.user.value.password.newPassword.length >= 8) ? true : false
      case 'case':
        return (this.user.value.password.newPassword.match('(?=.*[a-z])(?=.*[A-Z])')) ? true : false
      case 'number':
        return (this.user.value.password.newPassword.match('\\d+')
          && this.user.value.password.newPassword.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/))
          ? true
          : false
      case 'name':
        if (!new RegExp("\\b"+ this.user.value.password.newPassword +"\\b").test(this.user.value.email)) {
          return true
        } else {
          return false
        }

      default:
        break;
    }
  }

  ngOnDestroy() {
    if (this.signUpSubs) this.signUpSubs.unsubscribe()
  }
}
