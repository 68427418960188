/** App Core Modules */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AngularMaterialModule } from './angular-material.module';

/** App Core Components */
import { AppComponent } from './app.component';
import { NavigationComponent } from './core/navigation/navigation.component';
import { environment } from '../environments/environment';
import { SignUpComponent } from '../app/modules/auth/pages/sign-up/sign-up.component';

/** Firebase authentication & database & storage*/
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

/** NGX Translate */
import { TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

/** Shared components */
import { DeleteDialogComponent } from './shared/components/dialogs/delete-dialog/delete-dialog.component';
import { RegisterDeviceDialogComponent } from './shared/components/dialogs/register-device-dialog/register-device-dialog.component';
import { WarningDialogComponent } from './shared/components/dialogs/warning-dialog/warning-dialog.component';
import { FAQDialogComponent } from './shared/components/dialogs/faq-dialog/faq-dialog.component';
import { ErrorDialogComponent } from './core/authentication/error-dialog/error-dialog.component';
import { CustomDialogComponent } from './shared/components/dialogs/custom-dialog/custom-dialog.component';
import { LoadingDialogComponent } from './modules/users/components/loading-dialog/loading-dialog.component';

/** Shared helpers */
import { RolePipe } from './shared/pipes/role.pipe';
import { ValidityService } from './shared/services/validity.service';
import { HttpInterceptorService } from './core/authentication/http-interceptor.service';

/** 3rd party library */
import { LottieModule } from 'ngx-lottie';
import  player from 'lottie-web';

export function playerFactory() {
  return player
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/',  '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    DeleteDialogComponent,
    RegisterDeviceDialogComponent,
    WarningDialogComponent,
    RolePipe,
    SignUpComponent,
    FAQDialogComponent,
    CustomDialogComponent,
    ErrorDialogComponent,
    LoadingDialogComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFirestoreModule,
		AngularFireStorageModule,
		AngularFireDatabaseModule,
		AngularFireAuthModule,
		AngularFireFunctionsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    LottieModule.forRoot({ player: playerFactory })
  ],
  exports: [
    LottieModule
  ],
  providers: [
    ValidityService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true}
  ],
  entryComponents: [
    DeleteDialogComponent,
    RegisterDeviceDialogComponent,
    WarningDialogComponent,
    FAQDialogComponent,
    ErrorDialogComponent,
    CustomDialogComponent,
    LoadingDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
