<h1 mat-dialog-title>
  {{data.title}}
</h1>
<div mat-dialog-content>
  <p>{{data.body}}</p>
</div>
<div mat-dialog-actions align="end">
  <button type="button" class="cancel" (click)="onNoClick()" *ngIf="data.isCancel">
    {{'general.action-buttons.cancel' | translate}}
  </button>
  <button type="button" class="delete" (click)="onSubmit()" >
    {{'general.action-buttons.ok' | translate}}
  </button>
</div>
