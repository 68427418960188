import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { mergeMap, take } from "rxjs/operators";

import { ModifyCompany } from "../interfaces/modify-company.interface";
import { RegisterCompany } from "../interfaces/register-company.interface";
import { RegisterUser } from "../interfaces/register-user.interface";
import { ResetPassword } from "../interfaces/reset-password.interface";

@Injectable({
	providedIn: 'root'
})
export class ServerService {
  // TEST
  private readonly baseUrl = 'https://europe-west3-server-touchbud-next-play.cloudfunctions.net/app/api/v1'
  // PROD
  // private readonly baseUrl = 'https://europe-west3-project-touchbud-play-europe1.cloudfunctions.net/app/api/v1'
  private readonly headerWithoutAuth = new HttpHeaders({
    'Content-Type':  'application/json',
    'Accept-Language': this.translate.currentLang
  });

  constructor(
    private readonly http: HttpClient,
		private readonly afAuth: AngularFireAuth,
    private readonly translate: TranslateService,
  ) {}

  registerUser(user: RegisterUser, companyId: string) {
    return this.afAuth.idToken.pipe(
      take(1),
      mergeMap((token) => {
        return this.http.post(`${this.baseUrl}/companies/${companyId}/users`,
          user,
          { headers: this.getHeader(token)}
        )
      })
    )
  }

  registerCompany(company: RegisterCompany) {
    return this.afAuth.idToken.pipe(
      take(1),
      mergeMap((token) => {
        return this.http.post(`${this.baseUrl}/companies`,
          company,
          { headers: this.getHeader(token)}
        )
      })
    )
  }

  removeUser(companyId: string, id: string) {
    return this.afAuth.idToken.pipe(
      take(1),
      mergeMap((token) => {
        return this.http.post(`${this.baseUrl}/companies/${companyId}/users/${id}/remove`,
          null,
          {headers: this.getHeader(token)}
        )
      })
    )
  }

  removeCompany(companyId: string) {
    return this.afAuth.idToken.pipe(
      take(1),
      mergeMap((token) => {
        return this.http.delete(`${this.baseUrl}/companies/${companyId}`,
          { headers: this.getHeader(token)}
        )
      })
    )
  }

  changeCompanyData(companyId: string, companyData: ModifyCompany) {
    return this.afAuth.idToken.pipe(
      take(1),
      mergeMap((token) => {
        return this.http.put(`${this.baseUrl}/companies/${companyId}`,
          companyData,
          { headers: this.getHeader(token)}
        )
      })
    )
  }

  changePassword(id: string, body: ResetPassword) {
    return this.http.post(`${this.baseUrl}/users/${id}/resetpassword`,
      body,
      {headers: this.headerWithoutAuth}
    )
  }

  forgotPassword(email: string) {
    return this.http.get(`${this.baseUrl}/users/forgotpassword?email=${email}`,
      {headers: this.headerWithoutAuth}
    )
  }

  resendConfirmation(email: string) {
    return this.afAuth.idToken.pipe(
      take(1),
      mergeMap((token) => {
        return this.http.get(`${this.baseUrl}/users/resendconfirmationemail?email=${email}`,
          {headers: this.getHeader(token)}
        )
      })
    )
  }

  createMediaThumbnail(company: string, body: any, mediaType: string) {
    return this.afAuth.idToken.pipe(
      take(1),
      mergeMap((token) => {
        return this.http.post(`${this.baseUrl}/inventory/${company}/${mediaType}`,
          body,
          {headers: this.getHeader(token)}
        )
      })
    )
  }

  getStatistics(company: string, from: string, to: string) {
    return this.afAuth.idToken.pipe(
      take(1),
      mergeMap((token) => {
        return this.http.get(`${this.baseUrl}/companies/${company}/statistics?from=${from}&to=${to}`,
          {headers: this.getHeader(token)}
        )
      })
    )
  }

  getDashboard(company: string): Observable<any> {
    return this.afAuth.idToken.pipe(
      take(1),
      mergeMap((token) => {
        return this.http.get(`${this.baseUrl}/companies/${company}/dashboard`,
          {headers: this.getHeader(token)}
        )
      })
    )
  }

  screenshot(company: string, deviceId: string) {
    return this.afAuth.idToken.pipe(
      take(1),
      mergeMap((token) => {
        return this.http.get(`${this.baseUrl}/companies/${company}/devices/${deviceId}/screenshot`,
          {headers: this.getHeader(token)}
        )
      })
    )
  }

  renewLicence(company: string, selectedPackage: {packageName: string, amount: number}) {
    return this.afAuth.idToken.pipe(
      take(1),
      mergeMap((token) => {
        return this.http.post(`${this.baseUrl}/companies/${company}/purchase`,
          selectedPackage,
          {headers: this.getHeader(token)}
        )
      })
    )
  }

  private getHeader(token: string) {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'Accept-Language': this.translate.currentLang
    })
  }
}
