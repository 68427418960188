<section id="signUp" *ngIf="!loading">
  <form [formGroup]="user"
    (ngSubmit)="onSubmit(user.value)">
    <div class="details">
      <img src="../../../../../assets/styles/images/city_bg_1.png"
        class="bg-img">
      <div class="form-container">
        <div class="form-content">
          <div class="form-bg">
            <img src="../../../../../assets/styles/images/touchbud_play_logo.svg"
                class="touchbud-logo"/>
            <p class="welcome">
              {{'sign-up.title' | translate}}
            </p>
              <mat-form-field appearance="fill">
                <input matInput
                      [placeholder]="'login.form.email' | translate"
                      name="email"
                      type="email"
                      formControlName="email"
                      required>
              </mat-form-field>
              <ng-container formGroupName="password">
                <mat-form-field appearance="fill">
                  <input matInput
                        [placeholder]="'login.form.password' | translate"
                        name="password"
                        type="password"
                        formControlName="newPassword"
                        required
                        autofocus>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <input matInput
                        [placeholder]="'login.form.password-again' | translate"
                        name="password"
                        type="password"
                        formControlName="rePassword"
                        required>
                </mat-form-field>
              </ng-container>
              <div class="terms">
                <mat-checkbox class="example-margin"
                  formControlName="terms"></mat-checkbox>
                <p>
                  {{'general.terms.text1' | translate}}
                  <a href="https://touchbud.com/en/terms-conditions/"
                    target="_blank">{{'general.terms.text2' | translate}}</a>
                  {{'general.terms.text3'| translate}}
                  <a href="https://touchbud.com/en/privacy-policy-2/"
                  target="_blank">{{'general.terms.text4' | translate}}</a>
                </p>
              </div>
              <button type="submit"
                      [disabled]="!user.valid || !user.value.terms"
                      mat-button
                      class="login-button">
                {{'general.action-buttons.create-account' | translate}}
              </button>
          </div>
        </div>
      </div>
      <div class="password-desc-container">
        <div class="password-desc">
          <p class="title">
            {{'sign-up.password-condition.title' | translate}}
          </p>
          <div class="list-container">
            <div class="list" >
              <img [ngClass]="{'valid': checkPasswordCondition('length')}"
                    [src]="(checkPasswordCondition('length'))
                      ? '../../../../../assets/styles/images/icons/ic_check.svg'
                      : '../../../../../assets/styles/images/icons/ic_cancel.svg'">
              <p>{{'sign-up.password-condition.text1' | translate}}</p>
            </div>
            <div class="list" >
              <img  [ngClass]="{'valid': checkPasswordCondition('case')}"
                [src]="(checkPasswordCondition('case'))
                  ? '../../../../../assets/styles/images/icons/ic_check.svg'
                  : '../../../../../assets/styles/images/icons/ic_cancel.svg'">
              <p>{{'sign-up.password-condition.text2' | translate}}</p>
            </div>
            <div class="list" >
              <img  [ngClass]="{'valid': checkPasswordCondition('number')}"
                [src]="(checkPasswordCondition('number'))
                  ? '../../../../../assets/styles/images/icons/ic_check.svg'
                  : '../../../../../assets/styles/images/icons/ic_cancel.svg'">
              <p>{{'sign-up.password-condition.text3' | translate}}</p>
            </div>
            <div class="list" >
              <img  [ngClass]="{'valid': checkPasswordCondition('name')}"
                [src]="(checkPasswordCondition('name'))
                  ? '../../../../../assets/styles/images/icons/ic_check.svg'
                  : '../../../../../assets/styles/images/icons/ic_cancel.svg'">
              <p>{{'sign-up.password-condition.text4' | translate}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
<ng-container *ngIf="loading">
  <ng-lottie [options]="lottieOptions"></ng-lottie>
</ng-container>
