import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-loading-dialog",
  templateUrl: "./loading-dialog.component.html",
  styleUrls: ["./loading-dialog.component.scss"],
})
export class LoadingDialogComponent implements OnInit {
  // Lottie
  lottieOptions: AnimationOptions = {
    path: '/assets/animation/anim_email-first.json'
  };
  firstAnimationCompleted = false;
  lastAnimationCompleted = false;
  loadingStatusText = 'loading-component.sending'

  constructor(
    public dialogRef: MatDialogRef<LoadingDialogComponent>,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
  }

  animationControl(status?: number) {
    if (!this.firstAnimationCompleted) {
      this.lottieOptions = {
        path: '/assets/animation/anim_email-second.json'
      }
      this.firstAnimationCompleted = true;

      if (status !== undefined) {
        let path = (status === 0)
          ? '/assets/animation/anim_email-third-success.json'
          : '/assets/animation/anim_email-third-failed.json';

        (status === 0) 
          ? this.loadingStatusText = 'loading-component.successfull'
          : this.loadingStatusText = 'loading-component.failed'

        this.lottieOptions = {
          path: path,
          loop: false
        }

        this.lastAnimationCompleted = true;
      }

      this.cdRef.detectChanges();
    }
  }

  onClick(status: undefined | true): void {
    this.dialogRef.close(status);
  }
}
