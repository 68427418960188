import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CustomDialog } from "src/app/shared/interfaces/custom-dialog.interface";

@Component({
  selector: "app-custom-dialog",
  templateUrl: "./custom-dialog.component.html",
  styleUrls: ["./custom-dialog.component.scss"],
})
export class CustomDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CustomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomDialog
  ) {}

  ngOnInit() {}

  onSubmit() {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(undefined);
  }
}
