<h1 mat-dialog-title>
  {{
    (errorBody.fields)
      ? errorBody.title
      : 'general.error.operation.title' | translate
  }}
</h1>
<div class="container"
  mat-dialog-content>
  <p>{{
      (errorBody.fields)
        ? errorBody.message
        : 'general.error.operation.text1' | translate
    }}
  </p>
  <ng-container *ngIf="errorBody.fields">
    <p *ngFor="let field of errorBody.fields">
      {{field?.message}}
    </p>
  </ng-container>
  <img class="error-state-img"
    src="../../../../assets/styles/images/img_error-state-op.png">
</div>
<div mat-dialog-actions class="actions-container">
  <button type="button" class="cancel" (click)="onSubmit(undefined)">
    {{'general.action-buttons.cancel' | translate}}
  </button>
  <button type="button" class="save" (click)="onSubmit(true)">
    {{'general.action-buttons.retry' | translate}}
  </button>
</div>
