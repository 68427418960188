import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthenticationService } from '../authentication/authentication.service';
import { CompaniesService } from 'src/app/shared/services/companies.service';

import { User } from 'src/app/shared/interfaces/user.interface';
import { ActiveUser } from 'src/app/shared/interfaces/active-user.interface';
import { Company } from 'src/app/shared/interfaces/company.interface';
import { MENU_STRUCTURE } from '../../shared/constants/menu-structure';

@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
	menuStructure: Array<any> = MENU_STRUCTURE;
	urlSubs: Subscription;
	userSubs!: Subscription;
  companySubs: Subscription;
  activeCompanySubs: Subscription;
  companiesSubs!: Subscription;
  activePurchaseSubs!: Subscription;
  companyData: Company;
	activeMenu: string = 'home';
  loading = false;
  user!: User;
  version = '2.4';
  isOwner = false;
  isPackageValid = true;
  isPurchaseActive = false;

	constructor(
		public authService: AuthenticationService,
		private router: Router,
    private companiesService: CompaniesService
	) {
		this.urlSubs = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.activeMenu = event.url.split('/')[1];
			}
		});

    this.userSubs = this.authService.activeUser.subscribe((user) => {
      const activeUser = (user) ? user : this.authService.user;
      this.checkMenuVisibility(activeUser);
    })

    this.activeCompanySubs = this.authService.activeCompany
      .subscribe(() => {
        this.companiesService.getCompanyByIdSimple(JSON.parse(sessionStorage.getItem('activeCompany'))).subscribe((data) => {
          const company: Company = data.data();
          this.checkPackageValidity(company.licence.expiration.seconds)
        })
      })

    this.activePurchaseSubs = this.companiesService.activePurchase
      .subscribe((activePurchaseStatus: boolean) => {
        this.isPurchaseActive = activePurchaseStatus;
      })
	}

	ngOnInit() {
    if (this.authService.user) {
      if (this.authService.user.owner) {
        this.isOwner = true;

        this.companiesSubs = this.companiesService.getCompanies().subscribe((data: any) => {
          const companies: Array<Company> = data.map((e: any) => {
            return {
              id: e.payload.doc.id,
              ...e.payload.doc.data()
            };
          })

          companies.forEach(company => {
            if(company.purchaseActive) this.isPurchaseActive = true;
          });
        });
      }
    }
	}

  private checkPackageValidity(licenseDate: number): void {
    const expiration = licenseDate * 1000;
    const sevenDays = 86400 * 1000 * 7;
    const today = Date.now();

    this.isPackageValid = (expiration < today + sevenDays || today > expiration)
      ? true
      : false;
  }

  checkMenuVisibility(user: ActiveUser) {
    this.menuStructure.forEach(menu => {
      if (!menu.roles.includes(user.role)) {
        menu.active = false
      } else {
        menu.active = true
      }
    });
  }

	ngOnDestroy(): void {
		this.urlSubs.unsubscribe();
    this.userSubs.unsubscribe();
    this.activeCompanySubs.unsubscribe();
    if(this.companySubs) this.companySubs.unsubscribe();
    if(this.companiesSubs) this.companiesSubs.unsubscribe();
    if(this.activePurchaseSubs) this.activePurchaseSubs.unsubscribe();
	}
}

