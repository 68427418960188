import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";


@Component({
  selector: "app-expired-dialog",
  templateUrl: "./expired-dialog.component.html",
  styleUrls: ["./expired-dialog.component.scss"],
})
export class ExpiredDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ExpiredDialogComponent>,
    private readonly router: Router
  ) {}

  ngOnInit() {}

  onSave() {
    this.dialogRef.close(
      this.router.navigate(['login'])
    );
  }
}
