import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CompaniesService {
  activeCompany = new BehaviorSubject<string>('');
  activePurchase =  new BehaviorSubject<boolean>(false);

	constructor(
		private firestore: AngularFirestore
	) { }

  getCompanies() {
		return this.firestore.collection('companies').snapshotChanges();
	}

  getCompanyById(companyId: string) {
		return this.firestore.doc('companies/'+ companyId).snapshotChanges();
	}

  getCompanyByIdSimple(companyId: string) {
		return this.firestore.doc('companies/'+ companyId).get();
	}

  addUserToCompany(companyId: string, userId: string) {
    return this.firestore.collection('companies').doc(companyId).update({
      users: firebase.firestore.FieldValue.arrayUnion(userId)
    })
  }

  removeUserFromCompany(companyId: string, userId: string) {
    return this.firestore.collection('companies').doc(companyId).update({
      users: firebase.firestore.FieldValue.arrayRemove(userId)
    })
  }
}
