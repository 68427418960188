import { Injectable, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";

import { BehaviorSubject, Subscription } from 'rxjs';

import { User } from '../../shared/interfaces/user.interface';
import { UsersService } from 'src/app/modules/users/users.service';
import { CompanyRole } from 'src/app/shared/interfaces/company-role.interface';
import { take } from 'rxjs/operators';
import { ActiveUser } from 'src/app/shared/interfaces/active-user.interface';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
	providedIn: 'root'
})

export class AuthenticationService implements OnDestroy {
  public currentUser!: any
	public isLoggedIn = false;
  public userIdToken;
  public userIdTokenSubs: Subscription;
  public activeCompany = new BehaviorSubject<string>(JSON.parse(sessionStorage.getItem('activeCompany')));
  public activeCompanies =  new BehaviorSubject<Array<CompanyRole>>(null);
	public activeUser = new BehaviorSubject<ActiveUser>(JSON.parse(sessionStorage.getItem('activeUser')));

	private usersSubs!: Subscription;
  private userCompaniesSubs!: Subscription;

	constructor(
		private afAuth: AngularFireAuth,
		private router: Router,
		private usersService: UsersService,
    private translate: TranslateService
	) {}

	login(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password).then((credential: any) => {
      this.userIdTokenSubs = this.afAuth.idToken.subscribe((token) => {
        sessionStorage.setItem('activeIdToken', JSON.stringify(token));
      });
      this.isValidUser(credential.user);
    }).catch(() => {
      return 'error';
    });
	}

	logout(){
    return this.afAuth.signOut().then(() => {
      sessionStorage.removeItem('activeUser');
      sessionStorage.removeItem('activeCompany');
      sessionStorage.removeItem('activeIdToken');
      sessionStorage.removeItem('availableCompanies');
      sessionStorage.removeItem('activeLanguage');
      this.router.navigate(['login']);
    })
	}

	isValidUser(signingUser: any) {
		this.usersSubs = this.usersService.getUserById(signingUser.uid)
      .pipe(take(1))
      .subscribe(
      (data: any) => {
        this.currentUser = {
          id: data.payload.id,
          ...data.payload.data()
        };

        this.userCompaniesSubs = this.usersService.getUserCompanies(this.currentUser.id)
          .pipe(take(1))
          .subscribe((companyData) => {
          const userCompanies: Array<CompanyRole> = companyData.map((e: any) => {
            return {
              id: e.payload.doc.id,
              ...e.payload.doc.data()
            };
          });

          const activeUser = {
            id: this.currentUser.id,
            name: this.currentUser.name,
            email: this.currentUser.email,
            role: userCompanies[0].role,
            owner: this.currentUser.owner
          };

          this.translate.use(this.currentUser.language);

          sessionStorage.setItem('activeUser', JSON.stringify(activeUser));
          sessionStorage.setItem('activeCompany', JSON.stringify(userCompanies[0].id));
          sessionStorage.setItem('availableCompanies', JSON.stringify(userCompanies));
          sessionStorage.setItem('activeLanguage', JSON.stringify(this.currentUser.language));

          this.activeCompany.next(userCompanies[0].id);
          this.activeCompanies.next(userCompanies);
          this.activeUser.next(activeUser);

          this.router.navigate(['home']);
          this.isLoggedIn = true;
        })
		  },
      (err: any) => {
        console.log(err)
        this.router.navigate(['unregistered']);
      });
	}

	canRead(user: User): boolean {
		const allowed = ['admin', 'editor', 'subscriber'];
		return this.checkAuthorization(user, allowed);
	}

	canEdit(user: User): boolean {
		const allowed = ['admin', 'editor'];
		return this.checkAuthorization(user, allowed);
	}

	canDelete(user: User): boolean {
		const allowed = ['admin'];
		return this.checkAuthorization(user, allowed);
	}

  updateActiveCompany(company: string) {
    sessionStorage.setItem('activeCompany', JSON.stringify(company));
    this.activeCompany.next(company);
    const user = this.createActiveUser(company);
    this.updateActiveUser(user)
  }

  updateActiveUser(user: ActiveUser) {
    sessionStorage.setItem('activeUser', JSON.stringify(user));
    this.activeUser.next(user);
  }

  isAuthenticated(): boolean {
    if (sessionStorage.getItem('activeUser')
      && sessionStorage.getItem('activeCompany')
      && sessionStorage.getItem('activeIdToken')) {
      return true;
    } else {
      return false;
    }
  }

  get user(): any {
    return JSON.parse(sessionStorage.getItem('activeUser'));
  }

  get company(): any {
    return JSON.parse(sessionStorage.getItem('activeCompany'));
  }

  get availableCompanies(): any {
    return JSON.parse(sessionStorage.getItem('availableCompanies'));
  }

	private checkAuthorization(user: any, allowedRoles: string[]): boolean {
		if (!user) {
			return false;
		}

		allowedRoles.forEach(role => {
			if (role === user.role) {
				return true;
			}
		});

		return false;
	}

  private createActiveUser(company: string): ActiveUser {
    let user = this.user;
    this.availableCompanies.forEach(availableCompany => {
      if (availableCompany.id === company) {
        user.role = availableCompany.role;
      }
    });

    return user;
  }

	ngOnDestroy() {
		this.usersSubs.unsubscribe();
    this.userCompaniesSubs.unsubscribe();
    this.userIdTokenSubs.unsubscribe();
	}
}
