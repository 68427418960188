import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/authentication/authentication.guard';
import { SignUpComponent } from './modules/auth/pages/sign-up/sign-up.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren:  () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'forgot-password',
    loadChildren:  () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'resetpassword',
    component: SignUpComponent
  },
  {
    path: 'devices',
    loadChildren:  () => import('./modules/devices/devices.module').then(m => m.DevicesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'playlists',
    loadChildren:  () => import('./modules/playlists/playlists.module').then(m => m.PlaylistsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'media',
    loadChildren:  () => import('./modules/media/media.module').then(m => m.MediaModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    loadChildren:  () => import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren:  () => import('./modules/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'statistics',
    loadChildren:  () => import('./modules/statistics/statistics.module').then(m => m.StatisticsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'companies',
    loadChildren:  () => import('./modules/companies/companies.module').then(m => m.CompaniesModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
