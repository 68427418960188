export const MENU_STRUCTURE: Array<object> = [
	{
		id: 0,
		menuName: 'Home',
		menuRoute: 'home',
		menuStatus: true,
    menuNamePath: 'general.menu.home',
		roles: ['admin', 'subscriber', 'editor'],
		active: true,
		icon: '/assets/styles/images/icons/ic_home.svg'
	},
	{
		id: 1,
		menuName: 'Devices',
		menuRoute: 'devices',
		menuStatus: false,
    menuNamePath: 'general.menu.devices',
		roles: ['admin', 'subscriber', 'editor'],
		active: true,
		icon: '/assets/styles/images/icons/ic_devices.svg'
	},
	{
		id: 2,
		menuName: 'Playlists',
		menuRoute: 'playlists',
		menuStatus: false,
    menuNamePath: 'general.menu.playlists',
		roles: ['admin', 'subscriber', 'editor'],
		active: true,
		icon: '/assets/styles/images/icons/ic_playlists.svg'
	},
	{
		id: 3,
		menuName: 'Media',
		menuRoute: 'media',
		menuStatus: false,
    menuNamePath: 'general.menu.media',
		roles: ['admin', 'subscriber', 'editor'],
		active: true,
		icon: '/assets/styles/images/icons/ic_media-library.svg'
	},
	{
		id: 4,
		menuName: 'Statistics',
		menuRoute: 'statistics',
		menuStatus: false,
    menuNamePath: 'general.menu.statistics',
		roles: ['admin', 'subscriber', 'editor'],
		active: true,
		icon: '/assets/styles/images/icons/ic_statistics.svg'
	},
  {
		id: 5,
		menuName: 'Profile',
		menuRoute: 'profile',
		menuStatus: false,
    menuNamePath: 'general.menu.profile',
		roles: ['admin'],
		active: true,
		icon: '/assets/styles/images/icons/ic_users.svg'
	},
  {
		id: 6,
		menuName: 'Companies',
		menuRoute: 'companies',
		menuStatus: false,
    menuNamePath: 'general.menu.companies',
		roles: ['admin'],
		active: true,
		icon: '/assets/styles/images/icons/ic_companies.svg'
	}
];
