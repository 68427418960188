<div id="navigationHolder">
  <section *ngFor="let menuItem of menuStructure"
          class="menu-holder">
      <a [routerLink]="menuItem.menuRoute"
          [ngClass]="{'active-menu': activeMenu === menuItem.menuRoute}"
          *ngIf="menuItem.menuRoute !== 'companies'
            && menuItem.active">
        <img [src]="menuItem.icon" class="gray-filter">
        <p>
          <span class="package-validity"
            *ngIf="menuItem.menuRoute === 'profile' && isPackageValid"></span>
          {{menuItem.menuNamePath | translate}}
        </p>
      </a>
      <a [routerLink]="menuItem.menuRoute"
        [ngClass]="{'active-menu': activeMenu === menuItem.menuRoute}"
        *ngIf="menuItem.menuRoute === 'companies'
          && isOwner
          && menuItem.active"
        >
        <img [src]="menuItem.icon" class="gray-filter">
        <p>
          <span class="package-validity"
            *ngIf="isPurchaseActive"></span>
          {{menuItem.menuName}}</p>
      </a>
  </section>
  <div class="menu-holder logout">
    <a class="" (click)="authService.logout()">
      <img src="../../../assets/styles/images/icons/ic_account-logout.svg"
        class="gray-filter">
    </a>
  </div>
</div>
