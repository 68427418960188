import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from './core/authentication/authentication.service';
import { UsersService } from './modules/users/users.service';
import { FAQDialogComponent } from './shared/components/dialogs/faq-dialog/faq-dialog.component';
import { ActiveUser } from './shared/interfaces/active-user.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'touchbud-play';
  activeSelect = false;

  @ViewChild('snav') snav;

  constructor(
    public authService: AuthenticationService,
    public translate: TranslateService,
    private dialog: MatDialog,
    private usersService: UsersService
  ) {
    this.setActiveLanguage();

    if (sessionStorage.getItem('availableCompanies')) {
      this.authService.activeCompanies.next(JSON.parse(sessionStorage.getItem('availableCompanies')));
    }
  }

  public setActiveCompany(company: string): void {
    this.activeSelect = true;
    this.authService.updateActiveCompany(company);
    const activeUser = this.createActiveUser(company);
    this.authService.updateActiveUser(activeUser);
  }

  public useLanguage(language: string, userId: string): void {
    this.translate.use(language);
    sessionStorage.setItem('activeLanguage', JSON.stringify(language));
    this.usersService.changeLanguage(userId, language);
  }

  public openFaq(): void {
    this.dialog.open(FAQDialogComponent, {
      width: '1040px',
      panelClass: 'faq-dialog'
    })
  }

  private createActiveUser(company: string): ActiveUser {
    let user = this.authService.user;
    this.authService.availableCompanies.forEach(availableCompany => {
      if (availableCompany.id === company) {
        user.role = availableCompany.role;
      }
    });
    return user;
  }

  private setActiveLanguage() {
    const activeLanguage = JSON.parse(sessionStorage.getItem('activeLanguage'));
    (activeLanguage)
      ? this.translate.use(JSON.parse(sessionStorage.getItem('activeLanguage')))
      : this.translate.use('en');
  }
}
