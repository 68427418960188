<section class="dialog-container">
  <div class="title-container">
    <div class="text-container">
      <h1>{{'faq.title' | translate}}</h1>
      <p>
        {{'faq.text1' | translate}}
        <a href="mailto:help@touchbud.com">help@touchbud.com</a>
      </p>
    </div>
    <div class="filter-container">
      <mat-form-field appearance="fill">
        <input matInput
          [placeholder]="'faq.search' | translate"> <!-- TODO [(ngModel)]="search"-->
      </mat-form-field>
    </div>
  </div>
  <div class="content-container" >
    <ng-container *ngIf="!loading">
      <mat-accordion class="loading-fadeIn">
        <mat-expansion-panel *ngFor="let question of questionsByLanguage; let i = index;"
          (opened)="setPanelOpen(i)"
          (closed)="setPanelClosed(i)"
          hideToggle="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{question.q}}
            </mat-panel-title>
            <div class="icon-container">
              <img class="closed"
                src="../../../../../assets/styles/images/icons/ic_plus.svg"
                *ngIf="!openedPanels.includes(i)">
              <div class="opened"
                *ngIf="openedPanels.includes(i)"></div>
            </div>
          </mat-expansion-panel-header>
          <span>{{question.a}}</span>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
    <ng-container *ngIf="loading">
      <ng-container *ngFor="let loadingItem of [].constructor(12)">
        <div class="loading-pulse"
          style="height: 48px;
            width: 100%;
            border-radius: 4px;
            background-color: white;
            margin-bottom: 16px;"></div>
      </ng-container>
    </ng-container>
  </div>
  <div class="action-container">
    <button type="button" (click)="onNoClick()">
      {{'general.action-buttons.ok' | translate}}
    </button>
  </div>
</section>
