import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CompaniesService } from './companies.service';
import { Company } from '../interfaces/company.interface';

@Injectable()
export class ValidityService {
  company = new BehaviorSubject<Company>(null);

  constructor(
    private companiesService: CompaniesService
  ) {}

  getCompany(activeCompany: string) {
    this.companiesService.getCompanyByIdSimple(activeCompany).subscribe((data: any) => {
      let companyData = data.data();
      companyData.id = data.id;
      this.company.next(companyData);
    })
  }
}
