import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AnimationOptions } from "ngx-lottie";
import { Subscription } from "rxjs";
import { FAQ } from "src/app/shared/interfaces/faq.interface";
import { Question } from "src/app/shared/interfaces/question.interface";
import { FaqService } from "src/app/shared/services/faq.service";

@Component({
  selector: "app-faq-dialog",
  templateUrl: "./faq-dialog.component.html",
  styleUrls: ["./faq-dialog.component.scss"],
})
export class FAQDialogComponent implements OnInit, OnDestroy {
  faqSubs!: Subscription;
  questions: FAQ;
  questionsByLanguage: Array<Question>;
  loading = false;
  openedPanels = [];

  // Lottie
  lottieOptions: AnimationOptions = {
    path: '/assets/animation/anim_loading.json',
  };

  constructor(
    public dialogRef: MatDialogRef<FAQDialogComponent>,
    private faqService: FaqService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.loading = true;

    this.faqSubs = this.faqService.getFaq().subscribe((data: any) => {
      this.questions = {
				id: data.payload.id,
				...data.payload.data()
      };

      const activeTranslate = this.translate.currentLang;
      this.questionsByLanguage = this.questions[activeTranslate];

      this.loading = false;
    })
  }

  setPanelOpen(index: number) {
    this.openedPanels.push(index);
  }

  setPanelClosed(index: number) {
    this.openedPanels = this.openedPanels.filter(panel => panel !== index);
  }

  onNoClick(): void {
    this.dialogRef.close(undefined);
  }

  ngOnDestroy() {
    if(this.faqSubs) this.faqSubs.unsubscribe();
  }
}
